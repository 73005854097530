import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';

// import Chip from '@material-ui/core/Chip';
import Seacrch from './search';
import Actions from './actions';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

import { actions as clientListActions } from 'redux/cars/clientList/reducer';
import { getClientListTableData } from 'redux/cars/clientList/selector';
import { requests as reportsRequests } from 'redux/reports/reducer';
import { getStatusFile } from 'redux/reports/selector';

import DataTable from 'components/dataTable';

import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

// const formatDate = 'DD/MM/YYYY - hh:mm:ss';
const formatDate = 'DD/MM/YYYY';
const columns = [
  {
    prop: 'subtype',
    label: 'Tipo',
    format: (value) => {
      switch (value) {
        case '':
          return <FormattedMessage id="WF" />;
        case 'WRAP':
          return <FormattedMessage id="Wrap" />;
        case 'PPF':
          return <FormattedMessage id="PPF" />;
      }
    },
  },
  { prop: 'data.licensePlate', label: 'Dominio' },
  { prop: 'customer.firstname', label: 'Nombre' },
  { prop: 'customer.email', label: 'E-mail' },
  {
    prop: 'code',
    label: 'Garantía',
    format: (value, row) => {
      if (row.isClosed) {
        return value;
      } else {
        return (
          <Chip
            style={{
              backgroundColor: '#c919d6',
              color: '#FFF',
              fontWeight: 'bold',
            }}
            label={<FormattedMessage id="Cliente" />}
          />
        );
      }
    },
  },
  {
    prop: 'created',
    label: 'Creación',
    format: (value, row) => moment(value).format(formatDate),
  },
  {
    prop: 'expires',
    label: 'Vencimiento',
    format: (value, row) => moment(value).format(formatDate),
  },
  // {
  //   prop: 'hasComplaint',
  //   label: 'Reclamo',
  //   format: (value) => (
  //     <Chip
  //       color={value ? 'primary' : 'secondary'}
  //       label={value ? 'Abierto' : 'NO'}
  //     />
  //   ),
  // },
  {
    prop: 'uuid',
    label: 'Acciones',
    format: (value, row) => <Actions {...{ value, row }} />,
  },
];

const Table = ({ setFilter, downloadFile, file, ...props }) => {
  useEffect(() => {
    setFilter();
  }, [setFilter]);
  useEffect(() => {
    if (file.hasError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [file.hasError]);
  return (
    <>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-3">
          <Seacrch />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-1 offset-lg-8">
          <Button
            variant="outlined"
            onClick={downloadFile}
            disabled={file.isLoading}
          >
            <FormattedMessage id="Export..." />
          </Button>
        </div>
      </div>
      <div>
        <div className="kt-space-20" />
        <DataTable
          columns={columns}
          onChangePage={(e, v) =>
            setFilter({
              page: v + 1,
            })
          }
          onChangeRowsPerPage={(e) =>
            setFilter({
              page: 1,
              perPage: e.target.value,
            })
          }
          {...props}
        />
      </div>
    </>
  );
};

export const fileName = 'garantias-auto.csv';

export const mapStateToProps = (state) => ({
  ...getClientListTableData(state),
  file: getStatusFile(fileName)(state),
});
export const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(clientListActions.setFilter(filter)),
  downloadFile: () =>
    dispatch(
      reportsRequests.fetch({
        report: '/reports/garantias/auto',
        fileName,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
